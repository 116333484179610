import { Flex } from "antd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import styled from "styled-components";

import {
  Button,
  Card,
  getColor,
  Icon,
  Logo,
  NavigateLink,
  notification,
  scale,
  tracker,
} from "@gfw/corvus";
import { callbackManager, userLogin } from "@gfw/backend-connector";
import { Form, Input, InputGroup } from "@gfw/orion";

import BackgroundImage from "@src/assets/images/background.png";

const StyledFlex = styled(Flex)`
  margin-top: ${scale["lg"]};
`;

const Background = styled("div")`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

function LoginPage() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [{ username, password }, setState] = useState({
    username: "",
    password: "",
  });
  const location = useLocation();
  const navigate = useNavigate();

  const locationToRedirect =
    location.pathname && !location.pathname.includes("login")
      ? location.pathname
      : "/overview";

  const handleSubmit = () => {
    setIsLoading(true);

    const callbackId = callbackManager.add({
      onSuccess: (me) => {
        tracker.setUserId(me.userId);
        navigate(locationToRedirect, { replace: true });
        setIsLoading(false);
      },
      onError: ({ status }) => {
        if (status === 401) {
          notification.error({
            message: "Oops, something went wrong",
            description: "Email does not match the password",
          });
        } else if (status === 400) {
          notification.error({
            message: "Oops, something went wrong",
            description: "The format could be wrong, please check",
          });
        } else if ([429, 403, 500].includes(status)) {
          notification.error({
            message: "Oops, something went wrong",
          });
        }
        setIsLoading(false);
      },
    });

    dispatch(
      userLogin({
        username,
        password,
        isAdmin: true,
        callbackId,
      }),
    );
  };

  // show the login UI
  return (
    <Background>
      <Flex
        css={`
          width: 30vw;
          margin: 15vh auto;
        `}
        vertical
      >
        <Card
          styles={{
            header: {
              backgroundColor: getColor("gfw.600"),
              padding: "16px 8px",
            },
          }}
          title={
            <Flex align="center" justify="center">
              <Logo width="90%" />
            </Flex>
          }
        >
          <Form onSubmit={handleSubmit}>
            <StyledFlex gap="middle" vertical>
              <InputGroup width="full">
                <Icon fs="1.2em" ml="4px" type="user" />
                <Input
                  autoComplete="username"
                  data-testid="email"
                  id="username"
                  onChange={(e) =>
                    setState((state) => {
                      return { ...state, username: e.target.value };
                    })
                  }
                  placeholder="admin username"
                  type="email"
                  value={username}
                />
              </InputGroup>
              <InputGroup width="full">
                <Icon fs="1.2em" ml="4px" type="password" />
                <Input
                  autoComplete="current-password"
                  data-testid="password"
                  id="password"
                  onChange={(e) =>
                    setState((state) => {
                      return { ...state, password: e.target.value };
                    })
                  }
                  placeholder="admin password"
                  type="password"
                  value={password}
                />
              </InputGroup>
              <NavigateLink fontSize="xs" noPadding to="/forgot">
                Forgot password?
              </NavigateLink>
              <Button
                block
                data-testid="submit"
                disabled={!!isLoading}
                htmlType="submit"
                type="primary"
              >
                LOGIN
              </Button>
            </StyledFlex>
          </Form>
        </Card>
      </Flex>
    </Background>
  );
}

export default LoginPage;
