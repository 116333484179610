import { useMutation } from "react-query";
import React, { useEffect, useState } from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Icon,
  Loading,
  notification,
  Space,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

import CommentEditor from "./CommentEditor";
import CommentsList from "./CommentsList";

const enricheData = (data) =>
  data.map((e) => {
    return {
      ...e,
      datetime: `${e.createdAt.replace("T", " ").slice(0, -5)} GMT`,

      avatar: <Icon fs="xl2" mt="5px" type="userOutline" />,
      author: e.createdBy,
    };
  });

function ProfileCRM({ profile }) {
  const [comments, setComments] = useState([]);
  const [commentToEdit, setCommentToEdit] = useState();
  const { mutateAsync: getComments, isLoading } = useMutation(
    () => apiClient.get(`/crm/${profile.oid}/comments`),
    {
      onSuccess: (response) => {
        setComments(enricheData(response?.data?.comments));
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const { mutateAsync: addComment, isLoading: addingComment } = useMutation(
    (content) => apiClient.post(`/crm/${profile.oid}/comments`, { content }),
    {
      onSuccess: () => {
        notification.success({ message: "comment added" });
        getComments();
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const { mutateAsync: deleteComment } = useMutation(
    (commentOId) => apiClient.delete(`/crm/comments/${commentOId}`),
    {
      onSuccess: () => {
        notification.success({ message: "comment deleted" });
        getComments();
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const { mutateAsync: editComment, isLoading: editingComment } = useMutation(
    ({ commentOId, content }) => {
      return apiClient.patch(`/crm/comments/${commentOId}`, { content });
    },
    {
      onSuccess: () => {
        notification.success({ message: "comment edited" });
        getComments();
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  useEffect(() => {
    getComments();
  }, [getComments]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <CommentEditor
        addComment={addComment}
        comment={commentToEdit}
        editComment={editComment}
        setCommentToEdit={setCommentToEdit}
        submitting={addingComment || editingComment}
      />
      <Space>
        <Button disabled onClick={() => setCommentToEdit({})} type="primary">
          Create a comment
        </Button>
        <Button onClick={getComments}>Refresh</Button>
      </Space>
      <CommentsList
        comments={comments}
        deleteComment={deleteComment}
        setCommentToEdit={setCommentToEdit}
      />
    </>
  );
}

export default ProfileCRM;
