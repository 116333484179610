import { Comment } from "@ant-design/compatible";
import { List } from "antd";

import React from "react";

import { Button, Modal } from "@gfw/corvus";
import { Text } from "@gfw/orion";

const CommentsList = function ({ comments, setCommentToEdit, deleteComment }) {
  return (
    <List
      dataSource={comments}
      header={`${comments.length} ${
        comments.length > 1 ? "comments" : "comment"
      }`}
      itemLayout="horizontal"
      renderItem={(comment) => (
        <Comment
          {...comment}
          actions={[
            <Button
              disabled
              icon="edit"
              key="edit"
              mr="lg"
              noPadding
              onClick={() => setCommentToEdit(comment)}
              type="link"
            >
              Edit
            </Button>,
            <Modal
              hideFooterBorder
              hideHeaderBorder
              key="delete"
              onOk={() => deleteComment(comment._id)}
              title="Delete comment"
              trigger={
                <Button danger disabled icon="delete" noPadding type="link">
                  Delete
                </Button>
              }
            >
              <Text>Please confirm that you want to delete this comment</Text>
            </Modal>,
          ]}
          content={<p>{comment.content}</p>}
        />
      )}
    />
  );
};

export default CommentsList;
